import styled from 'styled-components';

export const Wrapper = styled.div`
    width: 100vw;
`;

export const Content = styled.div`
    height: calc(100vh - 140px);
    overflow-y:scroll;
    padding-bottom: 140px;
`;

export const WraperDetails = styled.div`
    position: absolute;
    top: 0;
    left:0;   
    right: 0;
    bottom: 0;
    overflow-y: scroll;
    z-index: 10;
    background: white;
`;